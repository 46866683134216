/* PrivacyPolicy.css */

.privacy-policy-container {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .privacy-policy-content {
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  h2 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  ul li {
    margin-bottom: 0.5rem;
  }
  
  p {
    margin-bottom: 1rem;
  }
  
  @media (max-width: 768px) {
    .privacy-policy-container {
      padding: 10px;
    }
  
    h1 {
      font-size: 1.6rem;
    }
  
    h2 {
      font-size: 1.2rem;
    }
  
    p, ul {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .privacy-policy-container {
      padding: 10px;
    }
  
    h1 {
      font-size: 1.4rem;
    }
  
    h2 {
      font-size: 1.1rem;
    }
  
    p, ul {
      font-size: 0.9rem;
    }
  
    ul li {
      margin-left: 20px;
    }
  }
  
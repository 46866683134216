.testimonial-item {
    height: 450px; /* Adjust as needed */
    overflow: hidden;
  }
  
  .testimonial-item img {
    width: 80px;
    height: 60px;
  }
  
  .testimonial-item .d-flex {
    align-items: center;
    border-bottom: 2px solid #302667;
  }
  /* Adjust the font size for mobile screens */
@media only screen and (max-width: 768px) {
  .testimonial-item {
    font-size: 14px; /* Decrease font size for better fit */
    line-height: 1.5; /* Adjust line-height for readability */
    height: auto; /* Ensure height is dynamic based on content */
    padding: 1px; /* Adjust padding to prevent text from being cut off */
    overflow-y: auto; /* Allow scrolling if content overflows */
  }
}

/* General testimonial container styling */
.testimonial-container {
  background-color: #f9f9f9;
  padding: 2px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-height: 400px; /* Set a maximum height to prevent excessive height */
}

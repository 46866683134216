/* Refund.css */

.refund-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .refund-head {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #2c3e50;
  }
  
  p {
    margin-bottom: 1.5rem;
  }
  
  .refund-section {
    margin-top: 1.5rem;
  }
  
  .refund-section h2 {
    font-size: 1.5rem;
    color: #34495e;
    margin-bottom: 0.5rem;
  }
  
  .refund-section p {
    margin-bottom: 1rem;
  }
  
  a {
    color: #3498db;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .thank-you {
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;
    color: #2980b9;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    h1 {
      font-size: 1.75rem;
    }
  
    .refund-section h2 {
      font-size: 1.25rem;
    }
  
    p, .refund-section p {
      font-size: 0.95rem;
    }
  }
  
.testimonials {
  padding: 80px 0;
  background: url("./asset/cta-bg.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  overflow: hidden; /* Ensure no overflow issues */
}

.testimonials::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(1, 3, 111, 0.8);
  z-index: 1; /* Ensure overlay is on top */
}

.testimonials .container {
  position: relative;
  z-index: 2; /* Ensure container is above the overlay */
}

.testimonials .testimonials-slider {
  position: relative;
  z-index: 2; /* Ensure slider is above the overlay */
}

.testimonials .testimonial-item {
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 2; /* Ensure testimonial item is above the overlay */
  padding: 20px; /* Add padding if needed */
  border-radius: 8px; /* Optional: Add rounded corners */
}

.testimonials .testimonial-item .test {
  width: 50%;
  max-width: 150px; /* Limit the size on larger screens */
  height: auto; /* Maintain aspect ratio */
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff;
}

.testimonials .testimonial-item h4 {
  font-size: 1.2rem;
  color: #ddd;
  margin: 0 0 15px 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.4);
  font-size: 20px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  color: #eee;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.4);
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #1acc8d;
}

@media (min-width: 992px) {
  .testimonials {
    height: 80vh !important;
  }
}

@media (max-width: 768px) {
  .testimonials {
    height: auto; /* Adjust height on smaller screens */
    padding: 80px 0;
  }
  
  .testimonials .testimonial-item {
    padding: 10px;
    height: 50vh;
  }

  .testimonials .testimonial-item .test {
    width: 110px;
    height: 110px;
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 1.2rem;
  }

  .testimonials .testimonial-item h4 {
    font-size: 1rem;
  }

  .testimonials .testimonial-item .quote-icon-left,
  .testimonials .testimonial-item .quote-icon-right {
    font-size: 18px;
  }

  .testimonials .testimonial-item p {
    font-size: 0.9rem;
  }
}

/* Terms.css */

.terms-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .terms-container h1 {
    font-size: 2rem;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .terms-container p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
  }
  
  .terms-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .terms-container a:hover {
    text-decoration: underline;
  }
  
  .intro {
    font-weight: bold;
    text-align: justify;
    margin-bottom: 20px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .section h2 {
    font-size: 1.5rem;
    color: #444;
    margin-bottom: 10px;
  }
  
  .section h3 {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 8px;
  }
  
  .section ol {
    padding-left: 20px;
  }
  
  .section ol li {
    margin-bottom: 5px;
  }
  
  /* Responsive design for mobile screens */
  @media (max-width: 600px) {
    .terms-container {
      padding: 10px;
    }
  
    .terms-container h1 {
      font-size: 1.8rem;
    }
  
    .terms-container p,
    .section h2,
    .section h3 {
      font-size: 1rem;
    }
  
    .section {
      margin-bottom: 15px;
    }
  
    .intro {
      text-align: left;
    }
  }
  